import decodeJwt from 'jwt-decode';

export const isAuthenticated = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return false;
  }
  return permissions === 'user' || permissions === 'admin' ? true : false;
};


/**
 * Sign up via backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const connectWallet = async (
  nonce: string,
  wallet: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(nonce.length > 0)) {
    throw new Error('Nonce was not provided');
  }
  if (!(wallet.length > 0)) {
    throw new Error('Wallet was not provided');
  }


  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('nonce', nonce);
  formData.append('publicAddress', wallet);

  const request = new Request('/api/v1/connect', {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('permissions', decodedToken.permissions);
  }

  return data;
};


export const faucetDrip = async (
  wallet: string
) => {
  // Assert email or password or password confirmation is not empty
  
  if (!(wallet.length > 0)) {
    throw new Error('Wallet was not provided');
  }


  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('publicAddress', wallet);

  const request = new Request('https://bot.exchange.io/api/v1/tools/faucet/drip', {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  return data;
};