import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routing from './Routing';

window.React1 = require('react');

ReactDOM.render(
    <Routing />,
    document.getElementById('root')
);

/*
  <React.StrictMode>
    <App />
  </React.StrictMode>*/
